import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { errorMessages } from '../../../../constants/errorMessages';

import ProjectModalFooter from '../ProjectModalFooter';
import CustomButton from '../../../reusable/Buttons/Button';
import Spinner from '../../../reusable/Spinner';
import AssessmentProjectReportsList from '../../AssessmentProject/components/AssessmentProjectReportsList';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right-thin.svg';

import { saveProjectReportsData } from '../../../../store/projects/actions';
import {
  generateReportNorms,
  generateReportIdealProfile,
  parseSolutionNorms,
  modifyNorms,
} from '../../../Respondents/RequestReportModal';
import {
  selectAvailableIdealProfiles,
  selectAvailableNorms,
  selectAvailableReports,
} from '../../../../store/respondents/selectors';
import { findLanguage } from '../../../Respondents/CreateInvite';
import { fetchAssessmentsNorms, fetchAvailableReports } from '../../../../store/respondents/actions';
import { selectAvailableLanguages } from '../../../../store/user/selectors';

const ReportsStep = ({ goPrev, goNext }) => {
  const initialData = useSelector((state) => state.projects.createAssessmentProject.reports);
  const assessmentId = useSelector((state) =>
    state.projects.createAssessmentProject.assessment.assessment.batteryID
      ? state.projects.createAssessmentProject.assessment.assessment.uses.split(';')
      : [state.projects.createAssessmentProject.assessment.assessment.testID],
  );
  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const reportsLanguages = useSelector((state) => state.reports.availableLanguages);
  const availableLanguages = useSelector(selectAvailableLanguages);
  const languages = React.useMemo(() => {
    const fixedReportsLanguages = reportsLanguages.map((entry) => {
      if (entry.code === 'CH-PRC') {
        return {
          ...entry,
          code: 'CH',
          value: 'CH',
        };
      }
      return entry;
    });

    const reportsLanguagesObj = fixedReportsLanguages.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.code]: curr,
      }),
      {},
    );

    return availableLanguages.filter((availableLanguage) => reportsLanguagesObj[availableLanguage.code]);
  }, [availableLanguages, reportsLanguages]);
  const fetchedReports = useSelector(selectAvailableReports);
  const reports = initialData.reports.length ? initialData.reports : fetchedReports;
  const norms = modifyNorms(useSelector(selectAvailableNorms));
  const idealProfiles = modifyNorms(useSelector(selectAvailableIdealProfiles));
  const user = useSelector((state) => state.user.user);
  const [optionsStatus, setOptionsStatus] = useState({ norms: false, idealProfiles: false });

  const { values, setFieldValue, handleSubmit } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      language: initialData.language,
      reports: initialData.reports,
      norms: initialData.norms,
      idealProfiles: initialData.idealProfiles,
    },
    validationSchema: Yup.object().shape({
      language: Yup.object()
        .nullable()
        .required(errorMessages.inputField),
      norms: Yup.object().nullable(),
      idealProfiles: Yup.object().nullable(),
    }),
    onSubmit: (values) => {
      const readyToBeSentReports = values.reports.reduce((acc, item) => {
        const items = item.reports
          .filter((item) => item.isChecked)
          .map((reportItem) => ({
            reportID: reportItem.reportID,
            reportSetID: item.reportSetID,
            normIDs: reportItem.isSolutionReport
              ? parseSolutionNorms(reportItem.solutionNorms)
              : generateReportNorms(reportItem, values.norms),
            idealProfileID: values.idealProfiles ? generateReportIdealProfile(reportItem, values.idealProfiles) : null,
            reportType: 0,
          }));
        return [...acc, ...items];
      }, []);
      dispatch(saveProjectReportsData({ ...values, reports: values.reports, readyToBeSentReports }));
      goNext();
    },
  });

  useEffect(() => {
    if (!languages.length || initialData.language) return;
    const language = findLanguage(languages, user && user.distributorDefaultLanguageID);
    setFieldValue('language', language);
  }, [user, languages, initialData, values]);

  // fetch available reports
  useEffect(() => {
    if (!values.language) return;
    setLoadingState(true);

    const payload = values.language.code === 'CH' ? { ...values.language, code: 'CH-PRC' } : values.languag;

    dispatch(
      fetchAvailableReports(assessmentId, payload, [], () => {
        setLoadingState(false);
      }),
    );
  }, [values.language]);

  useEffect(() => {
    dispatch(fetchAssessmentsNorms(assessmentId));
  }, []);

  const onLanguageChange = (lang) => {
    dispatch(saveProjectReportsData({ ...values, reports: [], idealProfiles: {}, norms: {} }));
    setFieldValue('language', lang);
  };

  const onNormChange = (norm) => {
    setFieldValue('norms', norm ? { ...values.norms, [norm.testID]: norm } : { ...values.norms });
  };

  const onIdealProfileChange = (idealProfile) => {
    setFieldValue(
      'idealProfiles',
      idealProfile ? { ...values.idealProfiles, [idealProfile.testID]: idealProfile } : { ...values.idealProfiles },
    );
  };
  // const isLoading = !languages.length;

  const checkedReports = values.reports ? values.reports.filter((item) => item.isChecked) : [];
  const isNextDisabled =
    !checkedReports.length ||
    (optionsStatus.norms && !(values.norms && Object.keys(values.norms).length)) ||
    (optionsStatus.idealProfiles && !(values.idealProfiles && Object.keys(values.idealProfiles).length));

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Form>
        <Title>{I18n.t('Reports')}</Title>
        <AssessmentProjectReportsList
          languages={languages}
          language={values.language}
          setLanguage={onLanguageChange}
          reports={reports}
          norms={norms}
          idealProfiles={idealProfiles}
          commonTests={assessmentId}
          onSelectedReportsChange={(data) => setFieldValue('reports', data)}
          onNormChange={(norm) => onNormChange(norm)}
          onIdealProfileChange={(idealProfile) => onIdealProfileChange(idealProfile)}
          activeIdealProfiles={values.idealProfiles}
          activeNorms={values.norms}
          setOptionsStatus={setOptionsStatus}
        />
      </Form>
      <Footer>
        <Button handler={goPrev} variant="secondary">
          <ArrowLeft />
          {I18n.t('Back')}
        </Button>
        <Button handler={handleSubmit} disabled={isNextDisabled}>
          {I18n.t('Next')} <ArrowRight />
        </Button>
      </Footer>
    </Container>
  );
};

ReportsStep.propTypes = {
  goPrev: func.isRequired,
  goNext: func.isRequired,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${(props) => props.theme.colors.mediumBlue};
  font-size: ${(props) => props.theme.fontSizes.normal};
  font-weight: 600;
  margin-bottom: 1.6rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 2.4rem;
  flex-grow: 1;
`;

const Footer = styled(ProjectModalFooter)`
  justify-content: space-between;
`;

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(180deg);
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

const Button = styled(CustomButton)`
  text-transform: uppercase;
  width: 12rem;
  justify-content: space-evenly;
`;

export default ReportsStep;
