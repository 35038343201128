import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import { push } from 'redux-first-history';
import styled from 'styled-components';
import { I18n } from 'react-redux-i18n';
import { ReactComponent as ExpandCollapse } from '../../assets/icons/blue-arrow-down-big.svg';
import { CATEGORY, ITEM, COMPETENCY, ITEMS_LABELS } from './constants/CustomCompetencies';
import ConfirmationModal from '../reusable/ConfirmationModal';
import PageWrapper from '../PageWrapper';
import SettingsTabs from './components/SettingsTabs/SettingsTabs';
import Spinner from '../reusable/Spinner';
import AccordionDND from '../reusable/AccordionDND/AccordionDND';
import * as actions from '../../table/actionsList';
import mapCustomCompetencies from './utils/customCompetencies.util';
import {
  addCompetencyCategory,
  addCompetencyQuestion,
  addCompetencyToCategory,
  deleteCompetenciesCategoryAction,
  deleteCompetencyAction,
  deleteCompetencyQuestionAction,
  fetchCompetenciesAction,
  resetCompetencies,
  updateCompetencyCategory,
  updateCompetencyQuestion,
  updateCompetencyToCategory,
} from '../../store/settings/competencies/actions';
import competenciesSelectors from '../../store/settings/competencies/selectors';
import AddCompetencyCategory from '../AddCompetencyCategory/AddCompetencyCategory';
import competenciesTableActions from '../../table/tableActions/competencies';
import AddCompetencyItem from '../AddCompetencyItem/AddCompetencyItem';
import AddCompetencyQuestion from '../AddCompetencyQuestion/AddCompetencyQuestion';
import ImportSurvey from '../360/ImportSurvey';
import ImportCreateSurvey from '../360/ImportSurvey/ImportSurveyCreateStep';

const CustomCompetencies = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [addCompetency, setAddCompetency] = useState({ visible: false, data: null });
  const [question, setQuestion] = useState({ visible: false, data: null });
  const [addCompetencyItem, setAddCompetencyItem] = useState({
    visible: false,
    category: null,
    competency: null,
  });
  const [removeData, setRemoveData] = useState({ type: null, ids: null });
  const [expandAll, setExpandAll] = useState(false);
  const [isDeletionModalVisible, setDeletionModalStatus] = useState(false);
  const [isOpenImportSurveyModal, setIsOpenImportSurveyModal] = useState(false);
  const [isOpenCreateSurveyModal, setIsOpenCreateSurveyModal] = useState(false);
  const [importeValues, setImporteValues] = useState([]);
  const dispatch = useDispatch();

  const currentLocale = useSelector((state) => state.i18n.locale);
  const list = useSelector(competenciesSelectors.selectData);
  const isLoading = useSelector(competenciesSelectors.selectIsLoading);

  useEffect(() => {
    dispatch(fetchCompetenciesAction());

    return () => {
      dispatch(resetCompetencies());
    };
  }, []);

  const importSurveyAction = {
    label: 'Import from CSV',
    handler: () => setIsOpenImportSurveyModal(true),
  };

  const tableActions = useMemo(() => {
    const actions = competenciesTableActions.map((item) => ({
      ...item,
      handler: () => setAddCompetency({ visible: true, data: null }),
    }));

    return [...actions, importSurveyAction];
  }, []);

  const onRemoveItemByType = (type, ids) => {
    setRemoveData({ type, ids });
    setDeletionModalStatus(true);
  };

  const singleRowHandlers = {
    [actions.ADD_COMPETENCY]: (data) => setAddCompetencyItem({ visible: true, category: data }),
    [actions.EDIT_COMPETENCY_CATEGORY]: (data) => {
      setAddCompetency({
        visible: true,
        data,
      });
    },
    [actions.DELETE_COMPETENCY_CATEGORY]: ({ competencyID }) => onRemoveItemByType(CATEGORY, [competencyID]),
    [actions.EDIT_COMPETENCY_ITEM]: (data) =>
      setAddCompetencyItem({
        visible: true,
        category: null,
        competency: data,
      }),
    [actions.DELETE_COMPETENCY_ITEM]: ({ competencyID }) => onRemoveItemByType(COMPETENCY, [competencyID]),
    [actions.ADD_QUESTION]: (data) => setQuestion({ visible: true, data }),
    [actions.EDIT_QUESTION]: (data) => setQuestion({ visible: true, data }),
    [actions.DELETE_QUESTION]: (data) => onRemoveItemByType(ITEM, data),
  };

  useEffect(() => {
    if (list.length) {
      setData(mapCustomCompetencies(list, singleRowHandlers, currentLocale));
    }
  }, [list]);

  const onAddCategory = (data, newCategory) => {
    const action = newCategory ? addCompetencyCategory(data) : updateCompetencyCategory(data);

    setAddCompetency({ visible: false, data: null });
    dispatch(action);
  };

  const onAddCompetencyItem = (data, newCompetency) => {
    const action = newCompetency ? addCompetencyToCategory(data) : updateCompetencyToCategory(data);

    setAddCompetencyItem({ visible: false, data: null });
    dispatch(action);
  };

  const onQuestionAdd = (data, newQuestion) => {
    const action = newQuestion ? addCompetencyQuestion(data) : updateCompetencyQuestion(data);

    setQuestion({ visible: false, data: null });
    dispatch(action);
  };

  const memoizedRemoveConfirm = useCallback(() => {
    const { type, ids } = removeData;

    switch (type) {
      case CATEGORY:
        dispatch(deleteCompetenciesCategoryAction(ids));
        break;
      case COMPETENCY:
        dispatch(deleteCompetencyAction(ids));
        break;
      case ITEM:
        dispatch(deleteCompetencyQuestionAction(ids));
        break;
      default:
    }

    setRemoveData({ type: null, ids: null });
    setDeletionModalStatus(false);
  }, [setDeletionModalStatus, removeData, setRemoveData]);

  const memoizedRemoveCancel = useCallback(() => {
    setRemoveData({ type: null, ids: null });
    setDeletionModalStatus(false);
  }, []);

  const refetchCompetencies = () => {
    dispatch(fetchCompetenciesAction());
  };

  const handleImportCompetenciesModalClose = (...args) => {
    if (args[0] === 'create_survey') {
      setImporteValues(args[1]);
      setIsOpenImportSurveyModal(false);
      setIsOpenCreateSurveyModal(true);
    } else {
      refetchCompetencies();
      setIsOpenImportSurveyModal(false);
    }
  };

  const handleSucessImportCreateSurvey = (url) => {
    setIsOpenCreateSurveyModal(false);
    navigate(url);
  };

  return (
    <StyledPageWrapper
      title={I18n.t('360 settings')}
      backButtonHandler={() => dispatch(push(`/settings`))}
      buttons={tableActions}
    >
      <SettingsTabs />
      <CompetenciesWrapper>
        <CompetenciesList>
          {!isLoading && (
            <ExpandCollapseAllWrapper>
              <ExpandCollapseAll onClick={() => setExpandAll(!expandAll)}>
                {expandAll ? I18n.t('Collapse all') : I18n.t('Expand all')}
                <IconWrapper isExpanded={expandAll}>
                  <StyledExpandCollapse />
                </IconWrapper>
              </ExpandCollapseAll>
            </ExpandCollapseAllWrapper>
          )}

          <AccordionDND expandAll={expandAll} data={data} isLoading={isLoading} />
          <Spinner isLoading={isLoading} text={I18n.t('Loading competencies')} full />
        </CompetenciesList>
      </CompetenciesWrapper>
      {addCompetency.visible && (
        <AddCompetencyCategory
          category={addCompetency.data}
          onClose={onAddCategory}
          onCancel={() => setAddCompetency({ visible: false, data: null })}
        />
      )}
      {addCompetencyItem.visible && (
        <AddCompetencyItem
          category={addCompetencyItem.category}
          competency={addCompetencyItem.competency}
          onCancel={() => setAddCompetencyItem({ visible: false, data: null, category: null })}
          onClose={onAddCompetencyItem}
        />
      )}
      {question.visible && (
        <AddCompetencyQuestion
          competency={question.data}
          onCancel={() => setQuestion({ visible: false, data: null })}
          onClose={onQuestionAdd}
        />
      )}
      {removeData.type && (
        <ConfirmationModal
          title={`${I18n.t(ITEMS_LABELS[removeData.type].title)}`}
          onClose={memoizedRemoveCancel}
          isVisible={isDeletionModalVisible}
          description={`${I18n.t('Are you sure you want to remove selected')} ${I18n.t(
            ITEMS_LABELS[removeData.type].description,
          )} ?`}
          onConfirm={memoizedRemoveConfirm}
          caution
        />
      )}
      {isOpenImportSurveyModal && (
        <ImportSurvey
          onClose={() => setIsOpenImportSurveyModal(false)}
          onSuccess={handleImportCompetenciesModalClose}
        />
      )}
      {isOpenCreateSurveyModal && (
        <ImportCreateSurvey
          importeValues={importeValues}
          onClose={() => setIsOpenCreateSurveyModal(false)}
          onSuccess={handleSucessImportCreateSurvey}
        />
      )}
    </StyledPageWrapper>
  );
};

const StyledPageWrapper = styled(PageWrapper)`
  overflow: visible;
`;

const CompetenciesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  overflow: auto;
`;

const CompetenciesList = styled.div`
  width: 746px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  svg {
    ${(props) => (props.isExpanded ? 'transform: rotate(180deg)' : null)};
  }
`;

const ExpandCollapseAllWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const ExpandCollapseAll = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 44px;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.lightBlue};
`;
const StyledExpandCollapse = styled(ExpandCollapse)`
  path {
    fill: ${(props) => props.theme.colors.lightBlue};
  }
`;

export default CustomCompetencies;
